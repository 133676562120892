<template>
  <main class="mb-8 mr-4 md:mr-0">
    <div>
      <trac-back-button>
        <div class="mt-x">Sales</div>
      </trac-back-button>
      <div class="flex items-center">
        <div>
          <h1 class="mt-3 font-medium w-32">Sales Details</h1>
        </div>
        <div class="w-full flex items-end justify-end">
          <div></div>
        </div>
      </div>
    </div>
    <div class="grid w-full md:grid-cols-5 row-gap-3 md:col-gap-5 mt-8">
      <div class="white-shadow bg-white rounded-lg p-4 flex">
        <div class="flex-column w-2/12 md:w-4/12">
          <img src="../../assets/svg/receipt.svg" />
        </div>
        <div class="flex-column">
          <div class="text-xs text-primaryGray">
            Receipt <br />
            Number
          </div>
          <div class="text-sm font-semibold uppercase mt-3">
            {{ history.receipt_number || "N/A" }}
          </div>
        </div>
      </div>

      <div class="white-shadow bg-white rounded-lg p-4 flex">
        <div class="flex-column w-2/12 md:w-4/12">
          <img src="../../assets/svg/trans_type.svg" />
        </div>
        <div class="flex-column">
          <div class="text-xs text-primaryGray">
            Transaction <br />
            Type
          </div>
          <div class="text-sm font-semibold mt-3 uppercase">
            {{ history.transaction_type || "N/A" }}
          </div>
        </div>
      </div>
      <div
        class="white-shadow relative bg-white rounded-lg p-4 flex flex-col justify-between"
      >
        <div class="flex">
          <div class="flex-column w-2/12 md:w-4/12">
            <img src="../../assets/svg/pay_method.svg" />
          </div>
          <div class="flex-column">
            <div class="text-xs text-primaryGray">
              Payment <br />
              Method
            </div>
            <div class="text-sm font-semibold mt-3 uppercase">
              {{ bankNameType(history.payment_method) }}
            </div>
          </div>
        </div>

        <!-- <div
          class="text-center font-medium cursor-pointer text-primaryBlue underline text-xs"
        >
          Change Payment Method
        </div> -->
      </div>
      <div class="white-shadow bg-white rounded-lg p-4 flex">
        <div class="flex-column w-2/12 md:w-4/12">
          <img src="../../assets/svg/amt_received.svg" />
        </div>
        <div class="flex-column">
          <div class="text-xs text-primaryGray">
            Amount <br />
            Received
          </div>
          <div class="text-sm font-semibold mt-3">
            {{ history.total_price | formatPrice }}
          </div>
          <div
            class="text-sm text-primaryBlue"
            v-if="
              history.loyalty_amount_tendered &&
                history.loyalty_amount_tendered > 0
            "
          >
            <span>Loyalty: </span
            ><span class="font-semibold mt-3">{{
              history.loyalty_amount_tendered | formatPrice
            }}</span>
          </div>
        </div>
      </div>
      <div
        class="white-shadow relative bg-white rounded-lg p-4 flex flex-col justify-between"
      >
        <div class="flex">
          <div class="flex-column w-2/12 md:w-4/12">
            <img src="../../assets/svg/pay_date.svg" />
          </div>
          <div class="md:flex-column">
            <div class="text-xs text-primaryGray">
              Payment <br />
              Date
            </div>
            <div class="text-sm font-semibold mt-3">
              {{ moment(history.created_at).format("Do-MMM-YYYY") }} <span class="text-primaryGray">{{ moment(history.created_at).format("hh:mm A") }}</span>
            </div>
          </div>
        </div>

        <div
          class="text-center hidden  md:block font-medium cursor-pointer text-primaryGray mt-5 text-xs"
        >
          {{ moment(history.created_at).format("hh:mm A") }}
        </div>
      </div>
    </div>

    <div class="grid md:flex flex-row gap-5 mt-5">
      <div class="flex-column w-full">
        <div class="bg-white py-6 px-8 h-full rounded-lg big-shadow">
          <div class="font-medium text-sm mb-6">Order Details</div>
          <perfect-scrollbar>
            <div class="max-heights">
              <div
                class="flex flex-row py-2 items-center border-t justify-between"
                v-for="item in history.items"
                :key="item._id"
              >
                <div class="flex items-center">
                  <div class="h-8 w-8">
                    <img
                      class="rounded w-full h-full object-cover"
                      v-if="!!item.product_image"
                      :src="item.product_image"
                      alt=""
                    />
                    <svg
                      v-else
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      version="1.1"
                      id="Capa_1"
                      x="0px"
                      y="0px"
                      viewBox="0 0 512 512"
                      style="enable-background: new 0 0 512 512"
                      xml:space="preserve"
                    >
                      <g>
                        <g>
                          <path
                            d="M446.575,0H65.425C29.349,0,0,29.35,0,65.426v381.149C0,482.65,29.349,512,65.425,512h381.15    C482.651,512,512,482.65,512,446.574V65.426C512,29.35,482.651,0,446.575,0z M481.842,446.575    c0,19.447-15.821,35.267-35.267,35.267H65.425c-19.447,0-35.268-15.821-35.268-35.267v-55.007l99.255-84.451    c3.622-3.082,8.906-3.111,12.562-0.075l62.174,51.628c5.995,4.977,14.795,4.569,20.304-0.946L372.181,209.77    c2.67-2.675,5.783-2.935,7.408-2.852c1.62,0.083,4.695,0.661,7.078,3.596l95.176,117.19V446.575z M481.842,279.865l-71.766-88.366    c-7.117-8.764-17.666-14.122-28.942-14.701c-11.268-0.57-22.317,3.672-30.294,11.662L212.832,326.681l-51.59-42.839    c-14.959-12.422-36.563-12.293-51.373,0.308l-79.712,67.822V65.426c0-19.447,15.821-35.268,35.268-35.268h381.15    c19.447,0,35.267,15.821,35.267,35.268V279.865z"
                          />
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            d="M161.174,62.995c-40.095,0-72.713,32.62-72.713,72.713c0,40.094,32.619,72.713,72.713,72.713s72.713-32.619,72.713-72.713    S201.269,62.995,161.174,62.995z M161.174,178.264c-23.466,0-42.556-19.091-42.556-42.556c0-23.466,19.09-42.556,42.556-42.556    c23.466,0,42.556,19.091,42.556,42.556S184.64,178.264,161.174,178.264z"
                          />
                        </g>
                      </g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                    </svg>
                  </div>
                  <div>
                    <span class="text-xs font-medium tracking-wide pl-2">{{
                      item.name
                    }}</span>
                  </div>
                </div>
                <div>
                  <span class="text-xs">{{ item.price | formatPrice }}</span>
                  <span class="text-xs"> X {{ item.quantity }}</span>
                </div>
              </div>
            </div>
          </perfect-scrollbar>
          <div class="flex flex-row justify-end mt-4">
            <span class="font-medium text-xl"
              >Total: {{ history.total_price | formatPrice }}</span
            >
          </div>
        </div>
      </div>
      <div class="flex-column w-full">
        <div class="bg-white py-6 px-8 p-6 h-full rounded-lg white-shadow">
          <span class="font-medium text-lg">Store Information</span>
          <div class="mt-8">
            <div
              style="grid-template-columns: 1fr 3fr"
              class="grid items-center"
            >
              <span class="text-xs text-primaryGray">Sold by:</span>
              <span class="text-xs text-primaryBlue font-medium underline">
                {{
                  history.sale_agent ? history.sale_agent : "not available"
                }}</span
              >
            </div>
            <div
              style="grid-template-columns: 1fr 3fr"
              class="grid items-center mt-2"
            >
              <span class="text-xs text-primaryGray">Store Name:</span>
              <span class="text-xs text-primaryBlue font-medium underline">{{
                history.store_name
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-row mt-5 justify-end gap-5">
      <trac-button
        :disabled="!history._id"
        @button-clicked="resendReceiptModal = true"
        class="uppercase"
        >Reissue receipt</trac-button
      >
      <!-- <trac-button
        @button-clicked="selectCustomerModal = true"
        class="uppercase"
        >Select customer</trac-button
      > -->
    </div>
    <trac-modal
      size="max-w-2xl"
      @close="resendReceiptModal = false"
      v-if="resendReceiptModal"
    >
      <ResendReceipt
        :clientEmail="clientEmail"
        @close="resendReceiptModal = false"
      ></ResendReceipt>
    </trac-modal>
    <trac-modal
      size="max-w-2xl"
      @close="selectCustomerModal = false"
      v-if="selectCustomerModal"
    >
      <SelectCustomer @close="selectCustomerModal = false"></SelectCustomer>
    </trac-modal>

    <trac-confirmation
      :active="csvActive"
      @close="csvActive = false"
      :type="csvType"
    >
      {{ message }}
    </trac-confirmation>
  </main>
</template>

<script>
import ResendReceipt from "./ResendReceipt";
import SelectCustomer from "./SelectCustomer";
import moment from "moment";
import { mapGetters } from "vuex";
import { GET_USER_BUSINESS_DATA } from "../../browser-db-config/localStorage";
import { pick } from "lodash";
import ClickOutside from "vue-click-outside";
import DatePicker from "vue2-datepicker";
import { Parser } from "json2csv";
import "vue2-datepicker/index.css";

export default {
  name: "SalesDetails",
  components: { SelectCustomer, ResendReceipt, DatePicker },
  directives: {
    ClickOutside,
  },
  data() {
    return {
      resendReceiptModal: false,
      selectCustomerModal: false,
      moment,
      clientEmail: "",
      downloadCsv: false,
      csvActive: false,
      csvType: false,
      message: "",
    };
  },

  computed: {
    ...mapGetters(["GET_ALL_SALES_HISTORY"]),
    history() {
      let salesHistory = [];

      if (this.GET_ALL_SALES_HISTORY.data) {
        salesHistory = this.GET_ALL_SALES_HISTORY.data.salesHistory || [];
      } else {
        salesHistory = [];
      }

      return salesHistory.find((x) => {
        return (
          x._id === this.$route.params.id ||
          x.offline_sale_id === this.$route.params.id
        );
      });
    },
    total() {
      let k = this.history.items.reduce((a, { price }) => a + price, 0);
      return k;
    },
    getStore() {
      let u = this.storeData.map((x, i) => {
        let k = {};
        k.name = x.name;
        k._id = x._id;
        return k;
      });
      let v = [{ name: "all", _id: "all" }, ...u];
      return v;
    },
    getAllCsv() {
      let u = this.storeData.map((x, i) => {
        let k = {};
        k.name = x.name;
        k._id = x._id;
        return k;
      });

      return u;
    },
  },
  methods: {
    drop() {
      this.csvDropdown = false;
    },
    bankNameType(x) {
      if (x == "bank_transfer") {
        return "Bank Transfer (Dynamic)";
      } else if (x == "fixed_account") {
        return "Bank Transfer (Fixed)";
      } else if (x == "own_bank_account") {
        return "Own Bank Account";
      } else {
        return x;
      }
    },
    async fetchAllStores() {
      await this.$store.dispatch("FETCH_ALL_STORES");
      let res = this.$store.getters["GET_ALL_STORES"];
      if (res.status) {
        this.storeData = res.data || {};
      }
    },
    async jsonPayment() {
      let k = [];
      this.csvStore.length
        ? (k = this.csvStore.map(({ _id }) => {
            return _id;
          }))
        : (k = []);

      let payload = {};
      payload.customerId = this.history.customer_id;
      payload.startDate = this.moment(this.csvDate[0]).format("YYYY-MM-DD");
      payload.endDate = this.moment(this.csvDate[1]).format("YYYY-MM-DD");
      payload.storeIds = {
        list_of_store_ids: k,
      };
      let res = await this.$store.dispatch(
        "GET_CUSTOMER_JSON_PAYMENT",
        payload
      );

      if (res.data.salesByCustomer) {
        let data = res.data.salesByCustomer;
        let mappedData = data.map((allData) => pick(allData, this.fields));
        let selected = this.fields;
        const opts = { selected };
        try {
          const parser = new Parser(opts);
          const csv = parser.parse(mappedData);
          this.generateBlob([csv], (dataUrl) => {
            const element = document.createElement("a");
            element.href = dataUrl;
            element.download = `payments-${moment()}.csv`;
            element.click();
            this.fields = [];
            this.csvDate = "";
            this.downloadCsv = false;
          });
        } catch (err) {
          // console.error(err);
        }
      } else {
        this.csvActive = true;
        this.message = "There is no available data";
        this.csvType = false;
      }
    },
    generateBlob(csv, callback) {
      const file = new Blob(["\ufeff", csv], {
        type: "text/csv;charset=utf-8",
      });
      callback(window.URL.createObjectURL(file));
    },
  },
  async created() {
    await this.fetchAllStores();
  },
  mounted() {
    this.clientEmail = GET_USER_BUSINESS_DATA().email;
  },
};
</script>

<style scoped>
.white-shadow {
  box-shadow: 0px 0px 20px rgba(22, 120, 176, 0.12);
}
.max-heights {
  max-height: 15rem;
  overflow-y: auto;
}
</style>
<style>
.dd {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}
.hov:hover {
  background: rgba(190, 182, 182, 0.173);
}
#csv2 .mx-input {
  height: 44px !important;
  font-size: 12px;
}
#csv2 .mx-input::placeholder {
  color: #333 !important;
}
</style>
