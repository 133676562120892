<template>
  <div class="px-4 pt-10">
    <div class="mb-8">
      <h1 class="uppercase font-medium">
        Select <span class="font-bold">Customer</span>
      </h1>
      <svg
        width="20"
        height="4"
        viewBox="0 0 20 4"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0 2C0 0.895431 0.895431 0 2 0H18C19.1046 0 20 0.895431 20 2C20 3.10457 19.1046 4 18 4H2C0.895431 4 0 3.10457 0 2Z"
          fill="url(#paint0_linear)"
        />
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="-9.46895"
            y1="2.21242"
            x2="-7.84897"
            y2="9.88217"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#06B6CB" />
            <stop offset="1" stop-color="#253B95" />
          </linearGradient>
        </defs>
      </svg>
    </div>
    <div
      class="mb-8 border rounded-lg w-full overflow-auto border-gray-200"
      style="height: 50vh"
    >
      <table class="w-full table-fixed overflow-hidden">
        <tbody>
          <tr
            v-for="(customer, index) in customers"
            class="h-16"
            :class="index % 2 === 0 ? '' : 'bg-gray-100'"
          >
            <td class="text-xs text-accentDark pl-8">{{ customer }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="flex flex-row gap-5 justify-end">
      <trac-button class="uppercase text-xs">Add Customer</trac-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "SelectCustomer",
  data() {
    return {
      customers: [
        'Abraham Aigbe',
        'Abraham Aigbe',
        'Abraham Aigbe',
        'Abraham Aigbe',
        'Abraham Aigbe',
        'Abraham Aigbe',
        'Abraham Aigbe',
        'Abraham Aigbe',
        'Abraham Aigbe',
        'Abraham Aigbe',
        'Abraham Aigbe',
        'Abraham Aigbe',
        'Abraham Aigbe',
        'Abraham Aigbe',
        'Abraham Aigbe',
        'Abraham Aigbe',
      ]
    }
  }
}
</script>

<style scoped>
</style>